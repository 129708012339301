import React, { ReactNode, useEffect } from 'react';

interface BaseLayoutProps {
    children: ReactNode;
    title: string;
    description?: string;
}

const styles = {
    container: {
        maxWidth: '100%',
        width: '400px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontFamily: 'Arial, sans-serif',
        boxSizing: 'border-box' as const,
    },
    title: {
        textAlign: 'center' as const,
        marginBottom: '20px',
        fontSize: '24px',
    },
    inputGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '16px',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '3px',
        fontSize: '16px',
        boxSizing: 'border-box' as const,
    },
    button: {
        width: '100%',
        padding: '12px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
        fontSize: '18px',
        transition: 'background-color 0.3s',
    },
    result: {
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#f0f0f0',
        borderRadius: '3px',
        textAlign: 'center' as const,
        fontWeight: 'bold',
        fontSize: '18px',
    },
    description: {
        textAlign: 'center' as const,
        marginBottom: '20px',
        fontSize: '16px',
        color: '#666',
    },
};

const BaseLayout: React.FC< BaseLayoutProps> = ({ children, title, description }) => {
    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
      @media (max-width: 600px) {
        body {
          font-size: 14px;
        }
        .calculator {
          padding: 10px;
          width: 100%;
        }
        .calculator h1 {
          font-size: 20px;
        }
        .calculator input,
        .calculator button {
          font-size: 14px;
          padding: 8px;
        }
        .calculator .result {
          font-size: 16px;
          padding: 10px;
        }
      }
    `;
        document.head.appendChild(styleElement);

        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    return (
        <div style={styles.container} className="calculator">
            <h1 style={styles.title}>{title}</h1>
            {description && <p style={styles.description}>{description}</p>}
            {children}
        </div>
    );
};

export default BaseLayout;
export { styles };
