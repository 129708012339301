import React, { useState, useMemo } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

interface CalculationHistory {
    rollsPerPack: number
    lengthPerRoll: number
    totalPrice: number
    costPerMeter: number
}

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const ToiletPaperCostCalculator = () => {
    const [rollsPerPack, setRollsPerPack] = useState('')
    const [lengthPerRoll, setLengthPerRoll] = useState('')
    const [totalPrice, setTotalPrice] = useState('')
    const [costPerMeter, setCostPerMeter] = useState<number | null>(null)
    const [history, setHistory] = useState<CalculationHistory[]>([])

    const isFormValid = rollsPerPack !== '' && lengthPerRoll !== '' && totalPrice !== ''

    const calculateCost = () => {
        const rolls = parseInt(rollsPerPack)
        const length = parseFloat(lengthPerRoll)
        const price = parseFloat(totalPrice)

        if (rolls && length && price) {
            const totalLength = rolls * length
            const cost = price / totalLength
            setCostPerMeter(cost)

            // Google AnalyticsにデータをGTM経由で送信
            window.dataLayer.push({
                event: 'toilet_paper_cost_calculation',
                rollsPerPack: rolls,
                lengthPerRoll: length,
                totalPrice: price,
                costPerMeter: cost
            })

            setHistory(prevHistory => [
                {
                    rollsPerPack: rolls,
                    lengthPerRoll: length,
                    totalPrice: price,
                    costPerMeter: cost
                },
                ...prevHistory
            ])

            setRollsPerPack('')
            setLengthPerRoll('')
            setTotalPrice('')
        } else {
            setCostPerMeter(null)
        }
    }

    const resetHistoryAndReload = () => {
        window.location.reload()
    }

    const cheapestCostPerMeter = useMemo(() => {
        return history.length > 1
            ? Math.min(...history.map(item => item.costPerMeter))
            : null
    }, [history])

    const title = "トイレットペーパーコスト計算機"
    const description = "どちらのトイレットペーパーがお得かを比較しましょう。複数商品を入力すれば、ロール数、長さ、価格から、どの商品がお得かを算出します。"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="rollsPerPack">ロール数:</label>
                <input
                    style={styles.input}
                    type="number"
                    id="rollsPerPack"
                    value={rollsPerPack}
                    onChange={(e) => setRollsPerPack(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="lengthPerRoll">1ロールの長さ (m):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="lengthPerRoll"
                    value={lengthPerRoll}
                    onChange={(e) => setLengthPerRoll(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="totalPrice">金額 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="totalPrice"
                    value={totalPrice}
                    onChange={(e) => setTotalPrice(e.target.value)}
                />
            </div>
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateCost}
                disabled={!isFormValid}
            >
                計算
            </button>
            {costPerMeter !== null && (
                <div style={styles.result}>
                    1mあたりのコスト: {costPerMeter.toFixed(3)}円
                </div>
            )}

            {history.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <h3>比較結果</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {history.map((item, index) => (
                            <li
                                key={index}
                                style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    backgroundColor: cheapestCostPerMeter !== null && item.costPerMeter === cheapestCostPerMeter ? '#e6ffe6' : '#f0f0f0',
                                    borderRadius: '5px',
                                    border: cheapestCostPerMeter !== null && item.costPerMeter === cheapestCostPerMeter ? '2px solid #4CAF50' : 'none'
                                }}
                            >
                                {item.rollsPerPack}ロール, {item.lengthPerRoll}m/ロール, {item.totalPrice}円
                                <br />
                                1mあたり: {item.costPerMeter.toFixed(3)}円
                                {cheapestCostPerMeter !== null && item.costPerMeter === cheapestCostPerMeter && (
                                    <span style={{ marginLeft: '10px', color: '#4CAF50', fontWeight: 'bold' }}>
                                        最安値
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetHistoryAndReload}>比較結果をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default ToiletPaperCostCalculator
