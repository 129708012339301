import React, { useEffect, useRef, useState } from 'react';
import BaseLayout from '../../Layouts/BaseLayout';
import { Helmet } from "react-helmet";

interface GameHistoryItem {
    score: number;
    averageReactionTime: string;
    reactionRate: string;
    assessmentName: string;
}

interface PlayerInfo {
    name: string;
    ageGroup: string;
}

const AGE_GROUPS = ['10代', '20代', '30代', '40代', '50代', '60代以上'];

const SpeedTapCounter: React.FC = () => {
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(30);
    const [targets, setTargets] = useState<boolean[]>(Array(15).fill(false));
    const [gameOver, setGameOver] = useState(false);
    const [difficulty, setDifficulty] = useState(1);
    const [gameStarted, setGameStarted] = useState(false);
    const [reactionTimes, setReactionTimes] = useState<number[]>([]);
    const [targetAppearanceTimes, setTargetAppearanceTimes] = useState<(number | null)[]>(Array(15).fill(null));
    const [totalTargetsAppeared, setTotalTargetsAppeared] = useState(0);
    const [gameHistory, setGameHistory] = useState<GameHistoryItem[]>([]);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState<string | null>(null);
    const [assessmentName, setAssessmentName] = useState<string>('');
    const [assessmentMessage, setAssessmentMessage] = useState<string>('');
    const [playerName, setPlayerName] = useState<string>('');
    const [playerHistory, setPlayerHistory] = useState<PlayerInfo[]>([]);
    const [totalScore, setTotalScore] = useState<number>(0);

    const timerRef = useRef<number | null>(null);
    const gameIntervalRef = useRef<number | null>(null);
    const targetTimersRef = useRef<NodeJS.Timeout[]>([]);

    const TARGET_DISPLAY_TIME = 2000; // 2 seconds

    useEffect(() => {
        if (gameStarted && timeLeft > 0 && !gameOver) {
            timerRef.current = window.setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        if (timerRef.current !== null) {
                            clearInterval(timerRef.current);
                        }
                        setGameOver(true);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => {
                if (timerRef.current !== null) {
                    clearInterval(timerRef.current);
                }
            };
        }
    }, [gameStarted, gameOver]);

    useEffect(() => {
        if (timeLeft === 0 && !gameOver) {
            setGameOver(true);
        }
    }, [timeLeft, gameOver]);

    useEffect(() => {
        if (gameOver) {
            const { name, message } = assessReflexes(reactionTimes);
            setAssessmentName(name);
            setAssessmentMessage(message);

            const averageReactionTime = reactionTimes.length > 0
                ? reactionTimes.reduce((a, b) => a + b, 0) / reactionTimes.length
                : 0;

            const calculatedTotalScore = calculateTotalScore(score, averageReactionTime);
            setTotalScore(calculatedTotalScore);

            const averageReactionTimeString = averageReactionTime.toFixed(2);
            const reactionRate = totalTargetsAppeared > 0
                ? ((score / totalTargetsAppeared) * 100).toFixed(2)
                : '0';

            setGameHistory(prevHistory => [
                ...prevHistory,
                {
                    score,
                    averageReactionTime: averageReactionTimeString,
                    reactionRate,
                    assessmentName: name,
                    totalScore: calculatedTotalScore
                }
            ]);

            window.dataLayer.push({
                event: 'speed_tap_count',
                player_name: playerName,
                age_group: selectedAgeGroup,
                score: score,
                average_reaction_time: averageReactionTimeString,
                reaction_rate: reactionRate,
                assessment_name: name,
                total_score: calculatedTotalScore,
            });
        }
    }, [gameOver, score, reactionTimes, totalTargetsAppeared, selectedAgeGroup, playerName]);

    useEffect(() => {
        if (gameStarted && !gameOver) {
            gameIntervalRef.current = window.setInterval(() => {
                setTargets(prevTargets => {
                    return prevTargets.map((_, index) => {
                        if (!prevTargets[index] && Math.random() < 0.1 * difficulty) {
                            setTotalTargetsAppeared(prev => prev + 1);
                            setTargetAppearanceTimes(prevTimes => {
                                const newTimes = [...prevTimes];
                                newTimes[index] = Date.now();
                                return newTimes;
                            });

                            targetTimersRef.current[index] = setTimeout(() => {
                                setTargets(currentTargets => {
                                    const updatedTargets = [...currentTargets];
                                    updatedTargets[index] = false;
                                    return updatedTargets;
                                });
                            }, TARGET_DISPLAY_TIME);

                            return true;
                        }
                        return prevTargets[index];
                    });
                });
            }, 1000 / difficulty);

            return () => {
                if (gameIntervalRef.current !== null) {
                    clearInterval(gameIntervalRef.current);
                }
                targetTimersRef.current.forEach(timer => clearTimeout(timer));
            };
        }
    }, [gameStarted, gameOver, difficulty]);

    useEffect(() => {
        if (timeLeft % 10 === 0 && timeLeft > 0) {
            setDifficulty(prev => Math.min(prev + 1, 3));
        }
    }, [timeLeft]);

    const sanitizeInput = (input: string): string => {
        // Remove any HTML tags and limit the length to 10 characters
        return input.replace(/[<>]/g, '').slice(0, 10);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sanitizedName = sanitizeInput(event.target.value);
        setPlayerName(sanitizedName);
    };

    const addPlayerToHistory = (name: string, ageGroup: string) => {
        if (name && ageGroup) {
            setPlayerHistory(prev => {
                const existingPlayerIndex = prev.findIndex(p => p.name === name);
                if (existingPlayerIndex !== -1) {
                    // Update existing player's age group
                    const updatedHistory = [...prev];
                    updatedHistory[existingPlayerIndex].ageGroup = ageGroup;
                    return updatedHistory;
                } else {
                    // Add new player
                    return [...prev, { name, ageGroup }];
                }
            });
        }
    };

    const selectPlayerFromHistory = (player: PlayerInfo) => {
        setPlayerName(player.name);
        setSelectedAgeGroup(player.ageGroup);
    };

    const tapTarget = (index: number) => {
        if (targets[index] && !gameOver && gameStarted) {
            setScore(prevScore => prevScore + 1);
            setTargets(prevTargets => {
                const newTargets = [...prevTargets];
                newTargets[index] = false;
                return newTargets;
            });

            const appearanceTime = targetAppearanceTimes[index];
            if (appearanceTime) {
                const reactionTime = Date.now() - appearanceTime;
                setReactionTimes(prevTimes => [...prevTimes, reactionTime]);
            }

            setTargetAppearanceTimes(prevTimes => {
                const newTimes = [...prevTimes];
                newTimes[index] = null;
                return newTimes;
            });

            clearTimeout(targetTimersRef.current[index]);
        }
    };

    const getAverageAgeForGroup = (ageGroup: string): number => {
        const ageMap: { [key: string]: number } = {
            '10代': 15,
            '20代': 25,
            '30代': 35,
            '40代': 45,
            '50代': 55,
            '60代以上': 65
        };
        return ageMap[ageGroup] || 30;
    };

    const calculateTotalScore = (taps: number, averageReactionTime: number): number => {
        const theoreticalMaxTaps = 30; // Assuming 1 tap per second for 30 seconds
        const reactionSpeedScore = 2000 / (averageReactionTime + 1000);

        const normalizedTapScore = (taps / theoreticalMaxTaps) * 100;
        const normalizedReactionSpeedScore = (reactionSpeedScore / 2) * 100;

        const weightedTotalScore = (normalizedTapScore * 0.6) + (normalizedReactionSpeedScore * 0.4);

        return Math.round(weightedTotalScore);
    };

    const assessReflexes = (reactionTimes: number[]) => {
        if (reactionTimes.length === 0 || !selectedAgeGroup) return { name: "判定不能", message: "データが不足しています。" };

        // 外れ値を除外（例：平均±2標準偏差の範囲外）
        const mean = reactionTimes.reduce((sum, time) => sum + time, 0) / reactionTimes.length;
        const stdDev = Math.sqrt(reactionTimes.reduce((sq, time) => sq + (time - mean) ** 2, 0) / reactionTimes.length);
        const validTimes = reactionTimes.filter(time => Math.abs(time - mean) <= 2 * stdDev);

        // 中央値を計算
        const sortedTimes = [...validTimes].sort((a, b) => a - b);
        const median = sortedTimes[Math.floor(sortedTimes.length / 2)];

        // 年齢に基づく調整
        const averageAge = getAverageAgeForGroup(selectedAgeGroup);
        const ageAdjustment = Math.max(0, (averageAge - 20) * 5); // 20歳を基準に、1歳につき5msの調整
        const adjustedMedian = median - ageAdjustment;

        const assessments = [
            { maxTime: 600, name: "瞬速の忍者", message: "信じられないほどの反射神経！あなたは忍者の世界でも伝説になれるでしょう。" },
            { maxTime: 650, name: "閃光のサムライ", message: "稲妻のような速さ！日本刀の達人も驚くほどの反射速度です。" },
            { maxTime: 700, name: "音速のスプリンター", message: "音よりも速い反応！陸上競技のスタートでは無敵でしょう。" },
            { maxTime: 750, name: "超人的アスリート", message: "オリンピック選手も顔負けの反射神経。どんなスポーツでも活躍できそうです。" },
            { maxTime: 800, name: "プロゲーマーの誇り", message: "eスポーツの世界で輝ける反射神経。操作の速さは芸術の域です。" },
            { maxTime: 850, name: "鷹の目を持つ狩人", message: "獲物の動きを瞬時に捉える鋭い反射能力。狩りの達人と呼べるでしょう。" },
            { maxTime: 900, name: "機敏なパイロット", message: "戦闘機パイロットのような反射神経。緊急時の対応も完璧でしょう。" },
            { maxTime: 950, name: "俊敏な格闘家", message: "相手の動きを先読みするかのような反射速度。格闘技の世界で輝けます。" },
            { maxTime: 1000, name: "鋭い観察眼の探偵", message: "微細な変化も見逃さない鋭い反射神経。名探偵の素質があります。" },
            { maxTime: 1050, name: "素早い手先の外科医", message: "精密な手術にも適した反射神経。患者の命を救う速さです。" },
            { maxTime: 1100, name: "機敏なバーテンダー", message: "複数のオーダーを同時にこなせる反射神経。お客様を魅了するでしょう。" },
            { maxTime: 1150, name: "柔軟な対応のセールスマン", message: "顧客の反応を瞬時に読み取る能力。ビジネスの場で活躍できます。" },
            { maxTime: 1200, name: "頼れる消防士", message: "緊急時にも冷静に対応できる反射神経。多くの命を救える素質があります。" },
            { maxTime: 1250, name: "熟練の料理人", message: "複数の調理を同時にこなせる反射神経。一流シェフの素質があります。" },
            { maxTime: 1300, name: "リズミカルなダンサー", message: "音楽に合わせて体を動かせる反射神経。舞台で輝ける素質があります。" },
            { maxTime: 1350, name: "冷静沈着な交渉人", message: "相手の反応を見極めて対応できる能力。難しい交渉も成功させられるでしょう。" },
            { maxTime: 1400, name: "思慮深い哲学者", message: "深く考えてから行動する姿勢。その熟考は大きな価値を生み出すでしょう。" },
            { maxTime: 1450, name: "温厚な教育者", message: "生徒の反応を丁寧に観察できる能力。多くの人々の成長を支援できるでしょう。" },
            { maxTime: 1500, name: "穏やかな禅マスター", message: "内なる平和を感じさせる反応速度。周囲に落ち着きを与えられるでしょう。" },
            { maxTime: Infinity, name: "時空を超越した賢者", message: "時間の流れさえも操るかのような反応。その独特の視点は新たな発見をもたらすかもしれません。" }
        ];

        const assessment = assessments.find(a => adjustedMedian <= a.maxTime);
        return assessment || { name: "判定不能", message: "判定不能です。もう一度挑戦してみてください。" };
    };


    const startGame = () => {
        if (selectedAgeGroup === null || !playerName) return;
        addPlayerToHistory(playerName, selectedAgeGroup);
        resetGame();
        setGameStarted(true);
    };

    const resetGame = () => {
        setGameStarted(false);
        setGameOver(false);
        setScore(0);
        setTimeLeft(30);
        setTargets(Array(15).fill(false));
        setDifficulty(1);
        setReactionTimes([]);
        setTargetAppearanceTimes(Array(15).fill(null));
        setTotalTargetsAppeared(0);
        setAssessmentName('');
        setAssessmentMessage('');
        targetTimersRef.current.forEach(timer => clearTimeout(timer));
        targetTimersRef.current = [];
    };

    const resetHistory = () => {
        window.location.reload();
    };

    const title = "スピードタップチャレンジ"
    const description = "15個の穴からランダムに色が変わる所をタップしてください。あなたの反射神経を測定します。"

    return (
        <BaseLayout title={title} description={description}>
            <Helmet>
                <title>{`${title} | 反射神経を測定！`}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.container}>
                {!gameStarted && !gameOver && (
                    <div style={styles.startScreen}>
                        <h2>プレイヤー名を入力してください（10文字以内）</h2>
                        <div style={styles.nameInputContainer}>
                            <input
                                type="text"
                                value={playerName}
                                onChange={handleNameChange}
                                placeholder="名前を入力（10文字まで）"
                                style={styles.nameInput}
                                maxLength={10}
                            />
                            <div style={styles.playerHistoryContainer}>
                                {playerHistory.map((player, index) => (
                                    <button
                                        key={index}
                                        onClick={() => selectPlayerFromHistory(player)}
                                        style={{
                                            ...styles.playerHistoryButton,
                                            backgroundColor: playerName === player.name ? '#4CAF50' : '#f0f0f0',
                                            color: playerName === player.name ? 'white' : 'black',
                                        }}
                                    >
                                        {player.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <h2>年代を選択してください</h2>
                        <div style={styles.ageButtonsContainer}>
                            {AGE_GROUPS.map((ageGroup) => (
                                <button
                                    key={ageGroup}
                                    onClick={() => setSelectedAgeGroup(ageGroup)}
                                    style={{
                                        ...styles.ageButton,
                                        backgroundColor: selectedAgeGroup === ageGroup ? '#4CAF50' : '#f0f0f0',
                                        color: selectedAgeGroup === ageGroup ? 'white' : 'black',
                                    }}
                                >
                                    {ageGroup}
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={startGame}
                            style={{
                                ...styles.button,
                                opacity: selectedAgeGroup && playerName ? 1 : 0.5,
                                cursor: selectedAgeGroup && playerName ? 'pointer' : 'not-allowed',
                            }}
                            disabled={!selectedAgeGroup || !playerName}
                        >
                            スタート
                        </button>
                    </div>
                )}
                {gameStarted && (
                    <div style={styles.scoreBoard}>
                        <span>スコア: {score}</span>
                        <span>残り時間: {timeLeft}秒</span>
                    </div>
                )}
                <div style={styles.grid}>
                    {targets.map((target, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.targetHole,
                                backgroundColor: target ? '#8B4513' : '#90EE90'
                            }}
                            onClick={() => tapTarget(index)}
                        >
                            {target && <div style={styles.target}/>}
                        </div>
                    ))}
                </div>
                {gameOver && (
                    <div style={styles.gameOver}>
                        <h2>ゲームオーバー！</h2>
                        <p>プレイヤー名: {playerName}</p>
                        <p>年代: {selectedAgeGroup}</p>
                        <p>タップ数: {score}</p>
                        <p>反応速度: {reactionTimes.length > 0 ? (reactionTimes.reduce((a, b) => a + b, 0) / reactionTimes.length).toFixed(2) : '0'}ms</p>
                        <p>反応率: {totalTargetsAppeared > 0 ? ((score / totalTargetsAppeared) * 100).toFixed(2) : '0'}%</p>
                        <p style={styles.assessmentName}>{assessmentName}</p>
                        <p style={styles.assessmentMessage}>{assessmentMessage}</p>
                        <button onClick={resetGame} style={styles.button}>
                            もう一度プレイ
                        </button>
                        <div style={styles.historyContainer}>
                            <h3>ゲーム履歴</h3>
                            <table style={styles.historyTable}>
                                <thead>
                                <tr>
                                    <th>ゲーム</th>
                                    <th>スコア</th>
                                    <th>反応速度 (ms)</th>
                                    <th>反応率 (%)</th>
                                    <th>判定</th>
                                </tr>
                                </thead>
                                <tbody>
                                {gameHistory.map((game, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{game.score}</td>
                                        <td>{game.averageReactionTime}</td>
                                        <td>{game.reactionRate}</td>
                                        <td>{game.assessmentName}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <button onClick={resetHistory} style={{...styles.button, backgroundColor: '#FF4136'}}>
                            履歴をリセット
                        </button>
                    </div>
                )}
            </div>
        </BaseLayout>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
    },
    startScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    ageButtonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '10px',
        maxWidth: '600px',
    },
    ageButton: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, color 0.3s',
    },
    button: {
        backgroundColor: '#4CAF50',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '12px',
    },
    scoreBoard: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '300px',
        marginBottom: '1rem',
        fontSize: '1.2rem',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '10px',
        backgroundColor: '#8FBC8F',
        padding: '20px',
        borderRadius: '10px',
    },
    targetHole: {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    target: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: '#8B4513',
    },
    gameOver: {
        textAlign: 'center',
        marginTop: '2rem',
    },
    assessmentName: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#4CAF50',
        marginTop: '1rem',
    },
    assessmentMessage: {
        fontSize: '1.2rem',
        color: '#333',
        marginBottom: '1rem',
    },
    historyContainer: {
        marginTop: '2rem',
    },
    historyTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    nameInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '300px',
    },
    nameInput: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '100%',
        marginBottom: '10px',
    },
    playerHistoryContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '20px',
    },
    playerHistoryButton: {
        padding: '10px 15px',
        fontSize: '16px',
        borderRadius: '20px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, color 0.3s',
        minWidth: '80px',
    },
    totalScore: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#4CAF50',
        marginTop: '1rem',
    },
};

export default SpeedTapCounter;
