import React, { useState, useMemo, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

type ConsumptionUnit = '合' | 'g' | 'ml'
type RiceUnit = 'kg' | 'g'

interface CalculationHistory {
    id: string
    riceAmount: number
    riceUnit: RiceUnit
    totalPrice: number
    consumptionPerMeal: number
    consumptionUnit: ConsumptionUnit
    costPerMeal: number
    totalMeals: number
}

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const RiceCostCalculator = () => {
    const [riceAmount, setRiceAmount] = useState('')
    const [selectedRiceAmount, setSelectedRiceAmount] = useState<number | null>(null)
    const [riceUnit, setRiceUnit] = useState<RiceUnit>('kg')
    const [totalPrice, setTotalPrice] = useState('')
    const [consumptionPerMeal, setConsumptionPerMeal] = useState('')
    const [consumptionUnit, setConsumptionUnit] = useState<ConsumptionUnit>('合')
    const [costPerMeal, setCostPerMeal] = useState<number | null>(null)
    const [totalMeals, setTotalMeals] = useState<number | null>(null)
    const [history, setHistory] = useState<CalculationHistory[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    const riceAmountOptions = [20, 10, 5, 3, 2, 1]

    useEffect(() => {
        setIsFormValid(
            riceAmount !== '' &&
            totalPrice !== '' &&
            consumptionPerMeal !== '' &&
            Number(riceAmount) > 0 &&
            Number(totalPrice) > 0 &&
            Number(consumptionPerMeal) > 0
        )
    }, [riceAmount, totalPrice, consumptionPerMeal])

    const handleRiceAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRiceAmount(e.target.value)
        setSelectedRiceAmount(null)
    }

    const handleRiceAmountButtonClick = (amount: number) => {
        setRiceAmount(amount.toString())
        setSelectedRiceAmount(amount)
    }

    useEffect(() => {
        setIsFormValid(
            riceAmount !== '' &&
            totalPrice !== '' &&
            consumptionPerMeal !== '' &&
            Number(riceAmount) > 0 &&
            Number(totalPrice) > 0 &&
            Number(consumptionPerMeal) > 0
        )
    }, [riceAmount, totalPrice, consumptionPerMeal])

    const calculateCost = () => {
        const rice = parseFloat(riceAmount)
        const price = parseFloat(totalPrice)
        const consumption = parseFloat(consumptionPerMeal)

        if (rice && price && consumption) {
            let totalGrams = riceUnit === 'kg' ? rice * 1000 : rice
            let consumptionGrams: number

            switch (consumptionUnit) {
                case '合':
                    consumptionGrams = consumption * 150 // 1合 ≈ 150g
                    break
                case 'g':
                    consumptionGrams = consumption
                    break
                case 'ml':
                    consumptionGrams = consumption * 0.8 // Assuming 1ml of uncooked rice ≈ 0.8g
                    break
            }

            const meals = totalGrams / consumptionGrams
            const cost = price / meals
            setCostPerMeal(cost)
            setTotalMeals(meals)

            // Google AnalyticsにデータをGTM経由で送信
            window.dataLayer.push({
                event: 'rice_cost_calculation',
                riceAmount: rice,
                riceUnit: riceUnit,
                totalPrice: price,
                consumptionPerMeal: consumption,
                consumptionUnit: consumptionUnit,
                costPerMeal: cost,
                totalMeals: meals
            })

            const newHistoryItem: CalculationHistory = {
                id: Date.now().toString(), // ユニークIDを生成
                riceAmount: rice,
                riceUnit: riceUnit,
                totalPrice: price,
                consumptionPerMeal: consumption,
                consumptionUnit: consumptionUnit,
                costPerMeal: cost,
                totalMeals: meals
            }

            setHistory(prevHistory => [newHistoryItem, ...prevHistory])

            // 価格のみをリセット
            setTotalPrice('')
        } else {
            setCostPerMeal(null)
            setTotalMeals(null)
        }
    }

    const deleteHistoryItem = (id: string) => {
        setHistory(prevHistory => prevHistory.filter(item => item.id !== id))
    }

    const resetHistoryAndReload = () => {
        window.location.reload()
    }

    const cheapestCostPerMeal = useMemo(() => {
        return history.length > 1
            ? Math.min(...history.map(item => item.costPerMeal))
            : null
    }, [history])

    const title = "お米コスト計算機"
    const description = "お米の購入量と価格から、1食あたりのコストと何食分になるかを計算します。複数の商品を比較して、最もお得なお米を見つけましょう。"

    const UnitSelector = ({ value, onChange, units }: { value: string, onChange: (value: any) => void, units: string[] }) => (
        <div style={{ display: 'flex' }}>
            {units.map((unit) => (
                <button
                    key={unit}
                    style={{
                        ...styles.button,
                        padding: '5px 10px',
                        marginLeft: '5px',
                        backgroundColor: value === unit ? '#4CAF50' : '#f0f0f0',
                        color: value === unit ? 'white' : 'black',
                    }}
                    onClick={() => onChange(unit)}
                >
                    {unit}
                </button>
            ))}
        </div>
    )

    const QuickInputButton = ({ amount, isSelected }: { amount: number; isSelected: boolean }) => (
        <button
            onClick={() => handleRiceAmountButtonClick(amount)}
            style={{
                padding: '10px 0',
                width: '100%',
                border: 'none',
                backgroundColor: isSelected ? '#4CAF50' : '#e0e0e0',
                color: isSelected ? 'white' : 'black',
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
            }}
        >
            {amount}
        </button>
    )

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={{...styles.label, marginBottom: '10px'}} htmlFor="riceAmount">お米の量:</label>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gap: '5px',
                    marginBottom: '10px',
                }}>
                    {riceAmountOptions.map(amount => (
                        <QuickInputButton
                            key={amount}
                            amount={amount}
                            isSelected={selectedRiceAmount === amount}
                        />
                    ))}
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                        style={{...styles.input, flexGrow: 1, marginRight: '10px'}}
                        type="number"
                        id="riceAmount"
                        value={riceAmount}
                        onChange={handleRiceAmountChange}
                    />
                    <UnitSelector value={riceUnit} onChange={setRiceUnit} units={['kg', 'g']}/>
                </div>
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="totalPrice">価格 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="totalPrice"
                    value={totalPrice}
                    onChange={(e) => setTotalPrice(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="consumptionPerMeal">1食の消費量:</label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                        style={{...styles.input, flexGrow: 1, marginRight: '10px'}}
                        type="number"
                        id="consumptionPerMeal"
                        value={consumptionPerMeal}
                        onChange={(e) => setConsumptionPerMeal(e.target.value)}
                    />
                    <UnitSelector value={consumptionUnit} onChange={setConsumptionUnit} units={['合', 'g', 'ml']}/>
                </div>
            </div>
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateCost}
                disabled={!isFormValid}
            >
                計算
            </button>
            {costPerMeal !== null && totalMeals !== null && (
                <div style={styles.result}>
                    <p>1食あたりのコスト: {costPerMeal.toFixed(2)}円</p>
                    <p>合計食数: {Math.floor(totalMeals)}食分</p>
                </div>
            )}

            {history.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <h3>比較結果</h3>
                    <ul style={{listStyleType: 'none', padding: 0}}>
                        {history.map((item) => (
                            <li
                                key={item.id}
                                style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    backgroundColor: cheapestCostPerMeal !== null && item.costPerMeal === cheapestCostPerMeal ? '#e6ffe6' : '#f0f0f0',
                                    borderRadius: '5px',
                                    border: cheapestCostPerMeal !== null && item.costPerMeal === cheapestCostPerMeal ? '2px solid #4CAF50' : 'none',
                                    position: 'relative',
                                }}
                            >
                                {item.riceAmount}{item.riceUnit}, {item.totalPrice}円, {item.consumptionPerMeal}{item.consumptionUnit}/食
                                <br/>
                                1食あたり: {item.costPerMeal.toFixed(2)}円, 合計: {Math.floor(item.totalMeals)}食分
                                {cheapestCostPerMeal !== null && item.costPerMeal === cheapestCostPerMeal && (
                                    <span style={{marginLeft: '10px', color: '#4CAF50', fontWeight: 'bold'}}>
                                        最安値
                                    </span>
                                )}
                                <button
                                    onClick={() => deleteHistoryItem(item.id)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        fontSize: '16px',
                                        color: '#888',
                                    }}
                                    aria-label="削除"
                                >
                                    ×
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetHistoryAndReload}>比較結果をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default RiceCostCalculator
