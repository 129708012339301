import React, { useState, useMemo, useEffect } from 'react'
import BaseLayout, { styles } from "../Layouts/BaseLayout"
import { Helmet } from 'react-helmet'

interface CalculationResult {
    id: number
    alcoholName: string
    totalDrinks: number
    costPerDrink: number
    alcoholPerDrink: number
    price: number
}

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const AlcoholCostCalculator: React.FC = () => {
    const [alcoholName, setAlcoholName] = useState<string>('')
    const [alcoholBottlePrice, setAlcoholBottlePrice] = useState<string>('')
    const [alcoholBottleVolume, setAlcoholBottleVolume] = useState<string>('')
    const [alcoholBottleVolumeUnit, setAlcoholBottleVolumeUnit] = useState<'ml' | 'L'>('ml')
    const [alcoholBottlePercentage, setAlcoholBottlePercentage] = useState<string>('')
    const [glassCapacity, setGlassCapacity] = useState<string>('')
    const [desiredAlcoholPercentage, setDesiredAlcoholPercentage] = useState<string>('')
    const [results, setResults] = useState<CalculationResult[]>([])
    const [isFormValid, setIsFormValid] = useState<boolean>(false)

    useEffect(() => {
        const requiredFields = [
            alcoholBottlePrice,
            alcoholBottleVolume,
            alcoholBottlePercentage,
            glassCapacity,
            desiredAlcoholPercentage
        ]
        setIsFormValid(requiredFields.every(field => field.trim() !== ''))
    }, [alcoholBottlePrice, alcoholBottleVolume, alcoholBottlePercentage, glassCapacity, desiredAlcoholPercentage])

    const handleNumberInput = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
        const value = e.target.value
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setter(value)
        }
    }

    const calculateAlcoholBottleCost = () => {
        const alcoholBottleVolumeInMl = alcoholBottleVolumeUnit === 'L' ? parseFloat(alcoholBottleVolume) * 1000 : parseFloat(alcoholBottleVolume)
        const drinkVolume = parseFloat(glassCapacity) * 0.7
        const alcoholPerDrink = (parseFloat(desiredAlcoholPercentage) * drinkVolume) / parseFloat(alcoholBottlePercentage)
        const totalDrinks = Math.floor(alcoholBottleVolumeInMl / alcoholPerDrink)
        const costPerDrink = parseFloat(alcoholBottlePrice) / totalDrinks
        const price = parseInt(alcoholBottlePrice)

        const newResult: CalculationResult = {
            id: Date.now(),
            alcoholName: alcoholName || `計算結果 ${results.length + 1}`,
            totalDrinks,
            costPerDrink: Math.round(costPerDrink * 100) / 100,
            alcoholPerDrink: Math.round(alcoholPerDrink * 100) / 100,
            price,
        }

        setResults([...results, newResult])

        // Google AnalyticsにデータをGTM経由で送信
        window.dataLayer.push({
            event: 'alcohol_cost_calculation',
            alcoholName: newResult.alcoholName,
            alcoholBottlePrice: parseFloat(alcoholBottlePrice),
            alcoholBottleVolumeInMl,
            alcoholBottlePercentage: parseFloat(alcoholBottlePercentage),
            glassCapacity: parseFloat(glassCapacity),
            desiredAlcoholPercentage: parseFloat(desiredAlcoholPercentage),
            result: newResult,
        })

        resetInputs()
    }

    const toggleVolumeUnit = () => {
        if (alcoholBottleVolumeUnit === 'ml') {
            setAlcoholBottleVolumeUnit('L')
            if (!isNaN(parseFloat(alcoholBottleVolume))) {
                setAlcoholBottleVolume((parseFloat(alcoholBottleVolume) / 1000).toString())
            }
        } else {
            setAlcoholBottleVolumeUnit('ml')
            if (!isNaN(parseFloat(alcoholBottleVolume))) {
                setAlcoholBottleVolume((parseFloat(alcoholBottleVolume) * 1000).toString())
            }
        }
    }

    const resetInputs = () => {
        setAlcoholName('')
        setAlcoholBottlePrice('')
        setAlcoholBottleVolume('')
        setAlcoholBottleVolumeUnit(alcoholBottleVolumeUnit)
        setAlcoholBottlePercentage('')
        setGlassCapacity('')
        setDesiredAlcoholPercentage('')
    }

    const resetAllResults = () => {
        window.location.reload()
    }

    const bestValueId = useMemo(() => {
        if (results.length < 2) return null
        return results.reduce((prev, current) =>
            prev.costPerDrink < current.costPerDrink ? prev : current
        ).id
    }, [results])

    const title = "お酒のコスト計算機: 1杯あたりいくら？ボトル購入時のコスパ計算"
    const description = "購入したウイスキーや焼酎、サワーのボトルから作るお酒のコストは 1 杯あたりどれくらい？お酒のボトルを購入するときのコスパ計算に役立ちます。"

    return (
        <BaseLayout title={title}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <p>購入したウイスキーや焼酎、サワーのボトルから作るお酒のコストは 1 杯あたりどれくらい？</p>
            <p>お酒のボトルを購入するときのコスパ計算に役立ちます。</p>
            <p>※ 炭酸水など割り物のコストは含みません</p>
            <div style={styles.inputGroup}>
                <label style={styles.label}>お酒の名前（任意）</label>
                <input
                    type="text"
                    value={alcoholName}
                    onChange={(e) => setAlcoholName(e.target.value)}
                    style={styles.input}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label}>お酒（ボトル）の価格 (円)</label>
                <input
                    type="text"
                    value={alcoholBottlePrice}
                    onChange={(e) => handleNumberInput(e, setAlcoholBottlePrice)}
                    style={styles.input}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label}>ボトルの容量 ({alcoholBottleVolumeUnit})</label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                        type="text"
                        value={alcoholBottleVolume}
                        onChange={(e) => handleNumberInput(e, setAlcoholBottleVolume)}
                        style={{...styles.input, flexGrow: 1, marginRight: '10px'}}
                    />
                    <button onClick={toggleVolumeUnit}
                            style={{...styles.button, padding: '5px 10px', fontSize: '14px'}}>
                        {alcoholBottleVolumeUnit === 'ml' ? 'L（リットル）に変更' : 'ml（ミリリットル）に変更'}
                    </button>
                </div>
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label}>お酒のアルコール度数 (%)</label>
                <input
                    type="text"
                    value={alcoholBottlePercentage}
                    onChange={(e) => handleNumberInput(e, setAlcoholBottlePercentage)}
                    style={styles.input}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label}>グラスの容量 (ml)</label>
                <input
                    type="text"
                    value={glassCapacity}
                    onChange={(e) => handleNumberInput(e, setGlassCapacity)}
                    style={styles.input}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label}>作りたいお酒のアルコール度数 (%)</label>
                <input
                    type="text"
                    value={desiredAlcoholPercentage}
                    onChange={(e) => handleNumberInput(e, setDesiredAlcoholPercentage)}
                    style={styles.input}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
                <button
                    onClick={calculateAlcoholBottleCost}
                    style={{...styles.button, width: '100%', opacity: isFormValid ? 1 : 0.5, cursor: isFormValid ? 'pointer' : 'not-allowed'}}
                    disabled={!isFormValid}
                >
                    計算して結果を追加
                </button>
            </div>
            {results.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <h3>比較結果</h3>
                    {results.map((result) => (
                        <div key={result.id} style={{
                            ...styles.result,
                            marginBottom: '10px',
                            backgroundColor: result.id === bestValueId ? '#e6ffe6' : '#f0f0f0',
                            borderLeft: result.id === bestValueId ? '5px solid #4CAF50' : 'none',
                            padding: '10px'
                        }}>
                            <h4>{result.alcoholName}</h4>
                            <p>価格: {result.price}円</p>
                            <p>1杯あたりに入れるお酒の量: {result.alcoholPerDrink}ml</p>
                            <p>作れるお酒の数: {result.totalDrinks}杯</p>
                            <p>1杯あたりのコスト: {result.costPerDrink}円</p>
                            {bestValueId !== null && result.id === bestValueId && (
                                <p style={{color: '#4CAF50', fontWeight: 'bold'}}>最も安いお酒です！</p>
                            )}
                        </div>
                    ))}
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <button
                            onClick={resetAllResults}
                            style={{...styles.button, backgroundColor: '#f44336', padding: '10px 20px', fontSize: '16px'}}
                        >
                            比較結果をリセット
                        </button>
                    </div>
                </div>
            )}
        </BaseLayout>
    )
}

export default AlcoholCostCalculator
