import React, { useState, useEffect, useRef } from 'react'
import BaseLayout from '../../Layouts/BaseLayout'
import { Helmet } from "react-helmet"

// window オブジェクトに dataLayer プロパティを追加
declare global {
    interface Window {
        dataLayer: any[];
    }
}

const TapCounter: React.FC = () => {
    const [taps, setTaps] = useState(0);
    const [timeLeft, setTimeLeft] = useState(10000);
    const [isActive, setIsActive] = useState(false);
    const [showRestart, setShowRestart] = useState(false);
    const [restartOpacity, setRestartOpacity] = useState(0);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const startTimeRef = useRef<number | null>(null);
    const tapAreaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // タッチデバイスかどうかを検出
        const detectTouchDevice = () => {
            setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
        };
        detectTouchDevice();

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            // タイムアップ時に Google Analytics にデータを GTM 経由で送信
            window.dataLayer.push({
                event: 'tap_count',
                tap_count: taps
            });

            setTimeout(() => {
                setShowRestart(true);
            }, 3000);
        }
    }, [timeLeft, taps]);

    useEffect(() => {
        if (showRestart) {
            const fadeInInterval = setInterval(() => {
                setRestartOpacity(prevOpacity => {
                    const newOpacity = prevOpacity + 0.1;
                    if (newOpacity >= 1) {
                        clearInterval(fadeInInterval);
                        return 1;
                    }
                    return newOpacity;
                });
            }, 50);
            return () => clearInterval(fadeInInterval);
        }
    }, [showRestart]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTap = () => {
        if (!isActive && timeLeft > 0) {
            setIsActive(true);
            startTimeRef.current = Date.now();
            intervalRef.current = setInterval(updateTimer, 10);
        }
        if (isActive && timeLeft > 0) {
            setTaps(prevTaps => prevTaps + 1);
        }
    };

    useEffect(() => {
        const tapArea = tapAreaRef.current;
        if (!tapArea) return;

        const touchStartHandler = (e: TouchEvent) => {
            e.preventDefault();
            handleTap();
        };

        const mouseDownHandler = (e: MouseEvent) => {
            if (!isTouchDevice) {
                e.preventDefault();
                handleTap();
            }
        };

        if (isTouchDevice) {
            tapArea.addEventListener('touchstart', touchStartHandler);
        } else {
            tapArea.addEventListener('mousedown', mouseDownHandler);
        }

        return () => {
            if (isTouchDevice) {
                tapArea.removeEventListener('touchstart', touchStartHandler);
            } else {
                tapArea.removeEventListener('mousedown', mouseDownHandler);
            }
        };
    }, [isTouchDevice, isActive, timeLeft, handleTap]);

    const updateTimer = () => {
        if (startTimeRef.current === null) return;
        const elapsed = Date.now() - startTimeRef.current;
        const remaining = Math.max(10000 - elapsed, 0);
        setTimeLeft(remaining);

        if (remaining <= 0) {
            setIsActive(false);
            if (intervalRef.current) clearInterval(intervalRef.current);
        }
    };

    const formatTime = (milliseconds: number) => {
        const seconds = Math.floor(milliseconds / 1000);
        const ms = milliseconds % 1000;
        return `${seconds}.${ms.toString().padStart(3, '0')}`;
    };

    const handleRestart = () => {
        window.location.reload();
    };

    const title = "ひたすらタップ！タップ回数測定機"
    const description = "10秒間、ただひたすらタップしましょう。タップ回数を計測します。ただただタップしてストレス解消したい時、自分のタップレベルを測定したい時、友達とタップレベルを競いたい時などに使えます。タップすると計測が開始されます。"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{`${title} | ストレス解消！あなたは何回タップできますか？`}</title>
                <meta name="description" content={description}/>
            </Helmet>

            <div style={styles.container}>
                {timeLeft === 0 ? (
                    <div style={styles.gameOver}>
                        <p>タイムアップ!!</p>
                        <p>あなたのタップ回数は</p>
                        <p><span style={styles.resultCount}>{taps}</span> 回</p>
                        <p>でした。</p>
                        {showRestart && (
                            <button
                                onClick={handleRestart}
                                style={{...styles.restartButton, opacity: restartOpacity}}
                            >
                                再度計測する
                            </button>
                        )}
                    </div>
                ) : (
                    <>
                        <div style={styles.timeLeft}>
                            残り時間: {formatTime(timeLeft)}
                        </div>
                        <div style={styles.taps}>
                            タップ回数: {taps}
                        </div>
                        <div
                            ref={tapAreaRef}
                            style={{
                                ...styles.tapButton,
                                backgroundColor: timeLeft > 0 ? '#3498db' : '#bdc3c7',
                                cursor: timeLeft > 0 ? 'pointer' : 'default',
                            }}
                        >
                            Tap!
                        </div>
                    </>
                )}
            </div>
        </BaseLayout>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#f0f0f0',
        fontFamily: 'Arial, sans-serif',
    },
    tapButton: {
        width: '200px',
        height: '200px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '2rem',
        fontWeight: 'bold',
        userSelect: 'none',
        marginTop: '20px',
        marginBottom: '20px',
    },
    timeLeft: {
        fontSize: '1.5rem',
        fontWeight: '600',
    },
    taps: {
        marginTop: '1rem',
        fontSize: '1.75rem',
        fontWeight: 'bold',
    },
    gameOver: {
        fontSize: '1.25rem',
        fontWeight: '600',
        textAlign: 'center',
    },
    resultCount: {
      fontSize: '32px',
    },
    restartButton: {
        marginTop: '1rem',
        padding: '10px 20px',
        fontSize: '1rem',
        fontWeight: 'bold',
        backgroundColor: '#2ecc71',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'opacity 0.5s ease-in',
    },
};

export default TapCounter;
