import React, { useState, useMemo } from 'react'
import BaseLayout, { styles } from "../Layouts/BaseLayout"
import { Helmet } from 'react-helmet'

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const SevenTenthsDrinkMixer: React.FC = () => {
    const [desiredAlcoholPercentage, setDesiredAlcoholPercentage] = useState<string>('')
    const [alcoholPercentage, setAlcoholPercentage] = useState<string>('')
    const [glassCapacity, setGlassCapacity] = useState<string>('')
    const [result, setResult] = useState<{ alcoholAmount: number; mixerAmount: number } | null>(null)

    const isFormValid = useMemo(() => {
        return (
            desiredAlcoholPercentage !== '' &&
            alcoholPercentage !== '' &&
            glassCapacity !== '' &&
            parseFloat(desiredAlcoholPercentage) > 0 &&
            parseFloat(alcoholPercentage) > 0 &&
            parseFloat(glassCapacity) > 0
        )
    }, [desiredAlcoholPercentage, alcoholPercentage, glassCapacity])

    const calculateMixRatio = (
        desiredAlcoholPercentage: number,
        alcoholPercentage: number,
        glassCapacity: number
    ): { alcoholAmount: number; mixerAmount: number } => {
        const totalVolume = glassCapacity * 0.7 // 7分目
        const alcoholAmount = (desiredAlcoholPercentage * totalVolume) / alcoholPercentage
        const mixerAmount = totalVolume - alcoholAmount
        return {
            alcoholAmount: Math.round(alcoholAmount * 10) / 10,
            mixerAmount: Math.round(mixerAmount * 10) / 10,
        }
    }

    const handleCalculate = () => {
        const calculatedResult = calculateMixRatio(
            parseFloat(desiredAlcoholPercentage),
            parseFloat(alcoholPercentage),
            parseFloat(glassCapacity)
        )
        setResult(calculatedResult)

        // Google AnalyticsにデータをGTM経由で送信
        window.dataLayer.push({
            event: 'seven_tenths_drink_calculation',
            desiredAlcoholPercentage: parseFloat(desiredAlcoholPercentage),
            alcoholPercentage: parseFloat(alcoholPercentage),
            glassCapacity: parseFloat(glassCapacity),
            calculatedResult: calculatedResult
        })
    }

    const title = "作りたいアルコール度数の最適なお酒と割り物の量を計算"
    const description = "お酒と割り物（炭酸水や水など）をどれくらいの割合・量を入れたら良いか計算します。"

    return (
        <BaseLayout title={title}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <p>氷を入れることも考えて、グラス容量 7 分目のお酒を作ります。グラスの容量に対して、お酒と割り物（炭酸水や水など）をどれくらいの割合・量を入れたら良いか計算します。</p>
            <div style={styles.container} className="seven-tenths-drink-mixer">
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        作りたいアルコール度数 (%)
                    </label>
                    <input
                        type="number"
                        value={desiredAlcoholPercentage}
                        onChange={(e) => setDesiredAlcoholPercentage(e.target.value)}
                        style={styles.input}
                        min="0"
                        step="0.1"
                        required
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        グラスの容量 (ml)
                    </label>
                    <input
                        type="number"
                        value={glassCapacity}
                        onChange={(e) => setGlassCapacity(e.target.value)}
                        style={styles.input}
                        min="0"
                        step="1"
                        required
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        お酒自体のアルコール度数 (%)
                    </label>
                    <input
                        type="number"
                        value={alcoholPercentage}
                        onChange={(e) => setAlcoholPercentage(e.target.value)}
                        style={styles.input}
                        min="0"
                        step="0.1"
                        required
                    />
                </div>
                <button
                    onClick={handleCalculate}
                    style={{
                        ...styles.button,
                        opacity: isFormValid ? 1 : 0.5,
                        cursor: isFormValid ? 'pointer' : 'not-allowed'
                    }}
                    disabled={!isFormValid}
                >
                    計算
                </button>
                {result && (
                    <div style={styles.result}>
                        お酒の量: {result.alcoholAmount}ml<br/>
                        割り物の量: {result.mixerAmount}ml
                    </div>
                )}
            </div>
        </BaseLayout>
    )
}

export default SevenTenthsDrinkMixer
