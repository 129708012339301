import React, { useState, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

interface DiscountComparison {
    id: string
    originalPrice: number | null
    discountedPrice: number | null
    discountAmount: number | null
    discountRate: number | null
}

const DiscountComparisonCalculator = () => {
    const [originalPrice, setOriginalPrice] = useState<string>('')
    const [discountedPrice, setDiscountedPrice] = useState<string>('')
    const [discountAmount, setDiscountAmount] = useState<string>('')
    const [discountRate, setDiscountRate] = useState<string>('')
    const [comparisonResults, setComparisonResults] = useState<DiscountComparison[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    useEffect(() => {
        const filledFields = [originalPrice, discountedPrice, discountAmount, discountRate]
            .filter(field => field !== '').length
        setIsFormValid(filledFields === 2)
    }, [originalPrice, discountedPrice, discountAmount, discountRate])

    const calculateDiscount = () => {
        let result: DiscountComparison = {
            id: Date.now().toString(),
            originalPrice: parseFloat(originalPrice) || null,
            discountedPrice: parseFloat(discountedPrice) || null,
            discountAmount: parseFloat(discountAmount) || null,
            discountRate: parseFloat(discountRate) || null,
        }

        if (result.originalPrice && result.discountedPrice) {
            result.discountAmount = result.originalPrice - result.discountedPrice
            result.discountRate = (result.discountAmount / result.originalPrice) * 100
        } else if (result.originalPrice && result.discountAmount) {
            result.discountedPrice = result.originalPrice - result.discountAmount
            result.discountRate = (result.discountAmount / result.originalPrice) * 100
        } else if (result.originalPrice && result.discountRate) {
            result.discountAmount = result.originalPrice * (result.discountRate / 100)
            result.discountedPrice = result.originalPrice - result.discountAmount
        } else if (result.discountedPrice && result.discountRate) {
            result.originalPrice = result.discountedPrice / (1 - result.discountRate / 100)
            result.discountAmount = result.originalPrice - result.discountedPrice
        } else if (result.discountedPrice && result.discountAmount) {
            result.originalPrice = result.discountedPrice + result.discountAmount
            result.discountRate = (result.discountAmount / result.originalPrice) * 100
        } else if (result.discountAmount && result.discountRate) {
            result.originalPrice = result.discountAmount / (result.discountRate / 100)
            result.discountedPrice = result.originalPrice - result.discountAmount
        }

        setComparisonResults(prevResults => [...prevResults, result])

        // Google AnalyticsにデータをGTM経由で送信
        window.dataLayer.push({
            event: 'discount_comparison_calculation',
            ...result
        })

        // Reset inputs after calculation
        setOriginalPrice('')
        setDiscountedPrice('')
        setDiscountAmount('')
        setDiscountRate('')
    }

    const deleteComparisonItem = (id: string) => {
        setComparisonResults(prevResults => prevResults.filter(item => item.id !== id))
    }

    const resetComparisonAndReload = () => {
        window.location.reload()
    }

    const lowestDiscountedPrice = comparisonResults.length > 1
        ? Math.min(...comparisonResults.map(item => item.discountedPrice || Infinity))
        : null

    const highestDiscountAmount = comparisonResults.length > 1
        ? Math.max(...comparisonResults.map(item => item.discountAmount || -Infinity))
        : null

    const title = "割引比較計算機"
    const description = "どちらの割引がお得かを計算し、比較しましょう。元の価格、割引後の価格、割引額、割引率のうち 2 つを入力してください。"

    const resultItemStyle: React.CSSProperties = {
        padding: '4px',
        margin: '2px 0',
        borderRadius: '4px',
    }

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title + "（どちらの割引がお得？）"}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="originalPrice">元の価格 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="originalPrice"
                    value={originalPrice}
                    onChange={(e) => setOriginalPrice(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="discountedPrice">割引後の価格 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="discountedPrice"
                    value={discountedPrice}
                    onChange={(e) => setDiscountedPrice(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="discountRate">割引率 (%):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="discountRate"
                    value={discountRate}
                    onChange={(e) => setDiscountRate(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="discountAmount">割引額 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="discountAmount"
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                />
            </div>
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateDiscount}
                disabled={!isFormValid}
            >
                計算 & 比較に追加
            </button>

            {comparisonResults.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <h3>比較結果</h3>
                    <ul style={{listStyleType: 'none', padding: 0}}>
                        {comparisonResults.map((item) => (
                            <li
                                key={item.id}
                                style={{
                                    marginBottom: '15px',
                                    padding: '10px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '5px',
                                    position: 'relative',
                                }}
                            >
                                <div style={resultItemStyle}>
                                    元の価格: {item.originalPrice?.toFixed(2)}円
                                </div>
                                <div style={{
                                    ...resultItemStyle,
                                    backgroundColor: lowestDiscountedPrice !== null && item.discountedPrice === lowestDiscountedPrice ? '#e6ffe6' : 'transparent',
                                }}>
                                    割引後の価格: {item.discountedPrice?.toFixed(2)}円
                                    {lowestDiscountedPrice !== null && item.discountedPrice === lowestDiscountedPrice && ' (最安値)'}
                                </div>
                                <div style={{
                                    ...resultItemStyle,
                                    backgroundColor: highestDiscountAmount !== null && item.discountAmount === highestDiscountAmount ? '#ffe6e6' : 'transparent',
                                }}>
                                    割引額: {item.discountAmount?.toFixed(2)}円
                                    {highestDiscountAmount !== null && item.discountAmount === highestDiscountAmount && ' (最大割引)'}
                                </div>
                                <div style={resultItemStyle}>
                                    割引率: {item.discountRate?.toFixed(2)}%
                                </div>
                                <button
                                    onClick={() => deleteComparisonItem(item.id)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        fontSize: '16px',
                                        color: '#888',
                                    }}
                                    aria-label="削除"
                                >
                                    ×
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetComparisonAndReload}>比較結果をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default DiscountComparisonCalculator
