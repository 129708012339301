import React, { useState, useEffect } from 'react'
import BaseLayout, { styles } from "../Layouts/BaseLayout"
import { Helmet } from 'react-helmet'

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const MixedDrinkCalculator: React.FC = () => {
    const [desiredAlcoholPercentage, setDesiredAlcoholPercentage] = useState<string>('')
    const [mixerAmount, setMixerAmount] = useState<string>('')
    const [alcoholPercentage, setAlcoholPercentage] = useState<string>('')
    const [result, setResult] = useState<string>('')
    const [isButtonActive, setIsButtonActive] = useState<boolean>(false)

    useEffect(() => {
        const allFieldsFilled = desiredAlcoholPercentage !== '' &&
            mixerAmount !== '' &&
            alcoholPercentage !== ''
        setIsButtonActive(allFieldsFilled)
    }, [desiredAlcoholPercentage, mixerAmount, alcoholPercentage])

    const calculateAlcoholAmount = (
        desiredAlcoholPercentage: number,
        mixerAmount: number,
        alcoholPercentage: number
    ): number => {
        const desiredAlcoholRatio = desiredAlcoholPercentage / 100
        const alcoholRatio = alcoholPercentage / 100
        const alcoholAmount = (desiredAlcoholRatio * mixerAmount) / (alcoholRatio - desiredAlcoholRatio)
        return Math.round(alcoholAmount * 100) / 100
    }

    const handleCalculate = () => {
        const alcoholNeeded = calculateAlcoholAmount(
            parseFloat(desiredAlcoholPercentage),
            parseFloat(mixerAmount),
            parseFloat(alcoholPercentage)
        )
        setResult(`必要なお酒の量: ${alcoholNeeded}ml`)

        // Google AnalyticsにデータをGTM経由で送信
        window.dataLayer.push({
            event: 'mixed_drink_calculation',
            desiredAlcoholPercentage: parseFloat(desiredAlcoholPercentage),
            mixerAmount: parseFloat(mixerAmount),
            alcoholPercentage: parseFloat(alcoholPercentage),
            alcoholNeeded: alcoholNeeded
        })
    }

    const title = "1 杯作るのに必要なお酒の量を計算"
    const description = "炭酸水など、割り物の量から、ウイスキーやサワー焼酎などをどれだけ入れれば好みの強さのお酒が作れるかを計算します。"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.container} className="mixed-drink-calculator">
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        アルコール度数は何度にしますか？ (%)
                    </label>
                    <input
                        type="number"
                        value={desiredAlcoholPercentage}
                        onChange={(e) => setDesiredAlcoholPercentage(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        割り物（炭酸水や水など）の量 (ml)
                    </label>
                    <input
                        type="number"
                        value={mixerAmount}
                        onChange={(e) => setMixerAmount(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        お酒そのもののアルコール度数 (%)
                    </label>
                    <input
                        type="number"
                        value={alcoholPercentage}
                        onChange={(e) => setAlcoholPercentage(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <button
                    onClick={handleCalculate}
                    style={{
                        ...styles.button,
                        opacity: isButtonActive ? 1 : 0.5,
                        cursor: isButtonActive ? 'pointer' : 'not-allowed'
                    }}
                    disabled={!isButtonActive}
                >
                    計算
                </button>
                {result && (
                    <div style={styles.result}>
                        {result}
                    </div>
                )}
            </div>
        </BaseLayout>
    )
}

export default MixedDrinkCalculator
