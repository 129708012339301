import React, {CSSProperties} from 'react'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import './App.css'
import MixedDrinkCalculator from './components/MixedDrinkCalculator'
import SevenTenthsDrinkMixer from './components/SevenTenthsDrinkMixer'
import AlcoholCostCalculator from './components/AlcoholCostCalculator'
import TissueCostCalculator from './components/TissueCostCalculator'
import ToiletPaperCostCalculator from './components/ToiletPaperCostCalculator'
import KitchenPaperCostCalculator from './components/KitchenPaperCostCalculator'
import RiceCostCalculator from "./components/RiceCostCalculator"
import UnitPriceCalculator from "./components/UnitPriceCalculator"
import DiscountComparisonCalculator from "./components/DiscountComparisonCalculator"
import MortgageCalculator from "./components/MortgageCalculator"
import NISACalculator from "./components/NISACalculator"
import TapCounter from "./components/games/TapCounter"
import SpeedTapCounter from "./components/games/SpeedTapCounter";

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    logo: {
        maxWidth: '100%',
    },
    heading: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px',
    },
    nav: {
        width: '100%',
        maxWidth: '600px',
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    li: {
        marginBottom: '10px',
    },
    link: {
        display: 'block',
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: 'white',
        textDecoration: 'none',
        borderRadius: '5px',
        textAlign: 'center',
        transition: 'background-color 0.3s',
    },
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    )
}

function AppContent() {
    const location = useLocation();

    return (
        <div className="App">
            {location.pathname !== '/' && (
                <p><Link to="/">Home</Link></p>
            )}

            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/mixed_drink_calculator" element={<MixedDrinkCalculator/>}/>
                <Route path="/seven_tenths_drink_mixer" element={<SevenTenthsDrinkMixer/>}/>
                <Route path="/alcohol_cost_calculator" element={<AlcoholCostCalculator/>}/>
                <Route path="/tissue_cost_calculator" element={<TissueCostCalculator/>}/>
                <Route path="/toilet_paper_cost_calculator" element={<ToiletPaperCostCalculator/>}/>
                <Route path="/kitchen_paper_cost_calculator" element={<KitchenPaperCostCalculator/>}/>
                <Route path="/rice_cost_calculator" element={<RiceCostCalculator/>}/>
                <Route path="/unit_price_calculator" element={<UnitPriceCalculator/>}/>
                <Route path="/discount_comparison_calculator" element={<DiscountComparisonCalculator/>}/>
                <Route path="/mortgage_calculator" element={<MortgageCalculator/>}/>
                <Route path="/nisa_calculator" element={<NISACalculator/>}/>
                <Route path="/tap_counter" element={<TapCounter/>}/>
                <Route path="/speed_tap_counter" element={<SpeedTapCounter/>}/>
            </Routes>
        </div>
    )
}

function Home() {
    return (
        <div>
            <Helmet>
                <title>生活コスト計算「DailyCalc（デイリーカルク）」</title>
                <meta name="description" content="日常生活に役立つ計算機集" />
            </Helmet>
            <div style={styles.container}>
                <img src="/logo.png" alt="DailyCalc Logo" style={styles.logo}/>
                <h1 style={styles.heading}>生活コスト計算「DailyCalc（デイリーカルク）」</h1>
                <p>日常生活に役立つ計算機集</p>
                <nav style={styles.nav} aria-label="日常生活">
                    <h2 className="visually-hidden">日常生活</h2>
                    <ul style={styles.ul}>
                        <li style={styles.li}>
                            <Link to={"/tissue_cost_calculator"}
                                  style={styles.link}>ティッシュペーパーコスト計算機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/toilet_paper_cost_calculator"}
                                  style={styles.link}>トイレットペーパーコスト計算機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/kitchen_paper_cost_calculator"}
                                  style={styles.link}>キッチンペーパーコスト計算機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/rice_cost_calculator"}
                                  style={styles.link}>お米コスト計算機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/unit_price_calculator"}
                                  style={styles.link}>単価計算機（1個あたりの価格比較）</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/discount_comparison_calculator"}
                                  style={styles.link}>割引比較計算機（どちらの割引がお得？）</Link>
                        </li>
                    </ul>
                </nav>

                <nav aria-label="お酒">
                    <h2 className="visually-hidden">お酒</h2>
                    <ul style={styles.ul}>
                        <li style={styles.li}>
                            <Link to={"/mixed_drink_calculator"} style={styles.link}>1
                                杯作るのに必要なお酒の量を計算</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/seven_tenths_drink_mixer"}
                                  style={styles.link}>作りたいアルコール度数の最適なお酒と割り物の量を計算</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/alcohol_cost_calculator"}
                                  style={styles.link}>ボトル購入時のお酒コスト比較計算機</Link>
                        </li>
                    </ul>
                </nav>

                <nav aria-label="資産">
                    <h2 className="visually-hidden">資産</h2>
                    <ul style={styles.ul}>
                        <li style={styles.li}>
                            <Link to={"/mortgage_calculator"} style={styles.link}>住宅ローン計算機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/nisa_calculator"} style={styles.link}>新NISA利益計算機</Link>
                        </li>
                    </ul>
                </nav>

                <nav aria-label="資産">
                    <h2 className="visually-hidden">ストレス解消・ゲーム</h2>
                    <ul style={styles.ul}>
                        <li style={styles.li}>
                            <Link to={"/tap_counter"} style={styles.link}>ひたすらタップ！タップ回数測定機</Link>
                        </li>
                        <li style={styles.li}>
                            <Link to={"/speed_tap_counter"} style={styles.link}>反射神経を測定！スピードタップチャレンジ</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default App
