import React, { useState, useMemo, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

interface CalculationHistory {
    sheetsPerRoll: number
    rollsPerPack: number
    totalPrice: number
    costPerSheet: number
}

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const KitchenPaperCostCalculator = () => {
    const [sheetsPerRoll, setSheetsPerRoll] = useState('')
    const [rollsPerPack, setRollsPerPack] = useState('')
    const [totalPrice, setTotalPrice] = useState('')
    const [costPerSheet, setCostPerSheet] = useState<number | null>(null)
    const [history, setHistory] = useState<CalculationHistory[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    useEffect(() => {
        setIsFormValid(
            sheetsPerRoll !== '' &&
            rollsPerPack !== '' &&
            totalPrice !== '' &&
            Number(sheetsPerRoll) > 0 &&
            Number(rollsPerPack) > 0 &&
            Number(totalPrice) > 0
        )
    }, [sheetsPerRoll, rollsPerPack, totalPrice])

    const calculateCost = () => {
        const sheets = parseInt(sheetsPerRoll)
        const rolls = parseInt(rollsPerPack)
        const price = parseFloat(totalPrice)

        if (sheets && rolls && price) {
            const totalSheets = sheets * rolls
            const cost = price / totalSheets
            setCostPerSheet(cost)

            // Google AnalyticsにデータをGTM経由で送信
            window.dataLayer.push({
                event: 'kitchen_paper_cost_calculation',
                sheetsPerRoll: sheets,
                rollsPerPack: rolls,
                totalPrice: price,
                costPerSheet: cost
            })

            setHistory(prevHistory => [
                {
                    sheetsPerRoll: sheets,
                    rollsPerPack: rolls,
                    totalPrice: price,
                    costPerSheet: cost
                },
                ...prevHistory
            ])

            setSheetsPerRoll('')
            setRollsPerPack('')
            setTotalPrice('')
        } else {
            setCostPerSheet(null)
        }
    }

    const resetHistoryAndReload = () => {
        window.location.reload()
    }

    const cheapestCostPerSheet = useMemo(() => {
        return history.length > 1
            ? Math.min(...history.map(item => item.costPerSheet))
            : null
    }, [history])

    const title = "キッチンペーパーコスト計算機"
    const description = "どのキッチンペーパーがお得かを比較しましょう。複数商品を入力すれば、枚数と価格から、どの商品が最もお買い得かを算出します"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="sheetsPerRoll">1ロールあたりのカット数:</label>
                <input
                    style={styles.input}
                    type="number"
                    id="sheetsPerRoll"
                    value={sheetsPerRoll}
                    onChange={(e) => setSheetsPerRoll(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="rollsPerPack">何ロールセットか(P):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="rollsPerPack"
                    value={rollsPerPack}
                    onChange={(e) => setRollsPerPack(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="totalPrice">金額 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="totalPrice"
                    value={totalPrice}
                    onChange={(e) => setTotalPrice(e.target.value)}
                />
            </div>
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateCost}
                disabled={!isFormValid}
            >
                計算
            </button>
            {costPerSheet !== null && (
                <div style={styles.result}>
                    1カットあたりのコスト: {costPerSheet.toFixed(3)}円
                </div>
            )}

            {history.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <h3>比較結果</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {history.map((item, index) => (
                            <li
                                key={index}
                                style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    backgroundColor: cheapestCostPerSheet !== null && item.costPerSheet === cheapestCostPerSheet ? '#e6ffe6' : '#f0f0f0',
                                    borderRadius: '5px',
                                    border: cheapestCostPerSheet !== null && item.costPerSheet === cheapestCostPerSheet ? '2px solid #4CAF50' : 'none'
                                }}
                            >
                                {item.sheetsPerRoll}カット/ロール, {item.rollsPerPack}ロールセット, {item.totalPrice}円
                                <br />
                                1枚あたり: {item.costPerSheet.toFixed(3)}円
                                {cheapestCostPerSheet !== null && item.costPerSheet === cheapestCostPerSheet && (
                                    <span style={{ marginLeft: '10px', color: '#4CAF50', fontWeight: 'bold' }}>
                                        最安値
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetHistoryAndReload}>比較結果をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default KitchenPaperCostCalculator
