import React, { useState, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

interface CalculationHistory {
    id: string
    quantity: number
    unit: string
    totalPrice: number
    unitPrice: number
}

const UnitPriceCalculator = () => {
    const [quantity, setQuantity] = useState('')
    const [unit, setUnit] = useState('個')
    const [totalPrice, setTotalPrice] = useState('')
    const [unitPrice, setUnitPrice] = useState<number | null>(null)
    const [history, setHistory] = useState<CalculationHistory[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    const units = ['個', 'g', 'kg', 'ml', 'L', '時間', '日']

    useEffect(() => {
        setIsFormValid(
            quantity !== '' &&
            totalPrice !== '' &&
            Number(quantity) > 0 &&
            Number(totalPrice) > 0
        )
    }, [quantity, totalPrice])

    const calculateUnitPrice = () => {
        const qty = parseFloat(quantity)
        const price = parseFloat(totalPrice)

        if (qty && price) {
            const unitPriceValue = price / qty
            setUnitPrice(unitPriceValue)

            // Google AnalyticsにデータをGTM経由で送信
            window.dataLayer.push({
                event: 'unit_price_calculation',
                quantity: qty,
                unit: unit,
                totalPrice: price,
                unitPrice: unitPriceValue
            })

            const newHistoryItem: CalculationHistory = {
                id: Date.now().toString(),
                quantity: qty,
                unit: unit,
                totalPrice: price,
                unitPrice: unitPriceValue
            }

            setHistory(prevHistory => [newHistoryItem, ...prevHistory])

            // Reset inputs after calculation
            setQuantity('')
            setTotalPrice('')
        } else {
            setUnitPrice(null)
        }
    }

    const deleteHistoryItem = (id: string) => {
        setHistory(prevHistory => prevHistory.filter(item => item.id !== id))
    }

    const resetHistoryAndReload = () => {
        window.location.reload()
    }

    const cheapestUnitPrice = history.length > 1
        ? Math.min(...history.map(item => item.unitPrice))
        : null

    const title = "単価計算機"
    const description = "商品やサービスの数量と価格から、1単位あたりの単価を計算します。複数の商品やサービスを比較して、最もお得な選択肢を見つけましょう。"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title + "（1単位あたりの価格比較）"}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="quantity">数量:</label>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    gap: '5px'
                }}>
                    {units.map((u) => (
                        <button
                            key={u}
                            style={{
                                ...styles.button,
                                flex: '1 1 0',
                                padding: '5px 2px',
                                backgroundColor: unit === u ? '#4CAF50' : '#f0f0f0',
                                color: unit === u ? 'white' : 'black',
                                fontSize: '0.9em',
                                minWidth: '0',
                            }}
                            onClick={() => setUnit(u)}
                        >
                            {u}
                        </button>
                    ))}
                </div>
                <input
                    style={styles.input}
                    type="number"
                    id="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="totalPrice">価格 (円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="totalPrice"
                    value={totalPrice}
                    onChange={(e) => setTotalPrice(e.target.value)}
                />
            </div>
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateUnitPrice}
                disabled={!isFormValid}
            >
                計算
            </button>
            {unitPrice !== null && (
                <div style={styles.result}>
                    <p>1{unit}あたりの単価: {unitPrice.toFixed(2)}円</p>
                </div>
            )}

            {history.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <h3>比較結果</h3>
                    <ul style={{listStyleType: 'none', padding: 0}}>
                        {history.map((item) => (
                            <li
                                key={item.id}
                                style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    backgroundColor: cheapestUnitPrice !== null && item.unitPrice === cheapestUnitPrice ? '#e6ffe6' : '#f0f0f0',
                                    borderRadius: '5px',
                                    border: cheapestUnitPrice !== null && item.unitPrice === cheapestUnitPrice ? '2px solid #4CAF50' : 'none',
                                    position: 'relative',
                                }}
                            >
                                {item.quantity}{item.unit}, 合計: {item.totalPrice}円
                                <br/>
                                1{item.unit}あたり: {item.unitPrice.toFixed(2)}円
                                {cheapestUnitPrice !== null && item.unitPrice === cheapestUnitPrice && (
                                    <span style={{marginLeft: '10px', color: '#4CAF50', fontWeight: 'bold'}}>
                                        最安値
                                    </span>
                                )}
                                <button
                                    onClick={() => deleteHistoryItem(item.id)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        fontSize: '16px',
                                        color: '#888',
                                    }}
                                    aria-label="削除"
                                >
                                    ×
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetHistoryAndReload}>比較結果をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default UnitPriceCalculator
