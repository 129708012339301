import React, { useState, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface CalculationResult {
    years: number;
    months: number;
    assetValue: number;
    totalInvestment: number;
    profitRate: number;
}

const NISACalculator = () => {
    const [monthlyTsumitate, setMonthlyTsumitate] = useState('')
    const [monthlyGrowth, setMonthlyGrowth] = useState('')
    const [interestRate, setInterestRate] = useState('')
    const [isFormValid, setIsFormValid] = useState(false)
    const [calculationResult, setCalculationResult] = useState<CalculationResult[]>([])
    const [yearsToLimit, setYearsToLimit] = useState(0)
    const [monthsToLimit, setMonthsToLimit] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')

    const tableStyles: { [key: string]: React.CSSProperties } = {
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
        },
        th: {
            backgroundColor: '#f2f2f2',
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'left',
        },
        td: {
            border: '1px solid #ddd',
            padding: '8px',
        },
        highlightRow: {
            backgroundColor: '#ffe6e6',
        },
    }

    useEffect(() => {
        const tsumitateValue = Number(monthlyTsumitate)
        const growthValue = Number(monthlyGrowth)
        const rateValue = Number(interestRate)

        let error = ''
        if (tsumitateValue > 10) {
            error = 'つみたて投資枠は最大10万円までです。'
        } else if (growthValue > 20) {
            error = '成長投資枠は最大20万円までです。'
        }
        setErrorMessage(error)

        setIsFormValid(
            error === '' &&
            interestRate !== '' &&
            rateValue > 0 &&
            (tsumitateValue > 0 || growthValue > 0)
        )
    }, [monthlyTsumitate, monthlyGrowth, interestRate])

    const calculateNISA = () => {
        const monthlyTsumitateValue = Number(monthlyTsumitate) * 10000
        const monthlyGrowthValue = Number(monthlyGrowth) * 10000
        const monthlyRate = Number(interestRate) / 100 / 12
        const investmentLimit = 1800 * 10000
        let tsumitateAsset = 0
        let growthAsset = 0
        let totalInvestment = 0
        let years = 0
        let months = 0
        const results: CalculationResult[] = []
        let limitReached = false

        while (years < 50) {
            years++
            for (let month = 1; month <= 12; month++) {
                months++
                if (!limitReached) {
                    const remainingInvestment = investmentLimit - totalInvestment
                    const monthlyInvestment = Math.min(monthlyTsumitateValue + monthlyGrowthValue, remainingInvestment)

                    if (monthlyInvestment > 0) {
                        const totalMonthly = monthlyTsumitateValue + monthlyGrowthValue
                        const tsumitateRatio = totalMonthly > 0 ? monthlyTsumitateValue / totalMonthly : 0
                        const growthRatio = 1 - tsumitateRatio

                        tsumitateAsset = (tsumitateAsset + monthlyInvestment * tsumitateRatio) * (1 + monthlyRate)
                        growthAsset = (growthAsset + monthlyInvestment * growthRatio) * (1 + monthlyRate)
                        totalInvestment += monthlyInvestment

                        if (totalInvestment >= investmentLimit) {
                            limitReached = true
                            setYearsToLimit(Math.floor(months / 12))
                            setMonthsToLimit(months % 12)
                            results.push({
                                years: Math.floor(months / 12),
                                months: months % 12,
                                assetValue: tsumitateAsset + growthAsset,
                                totalInvestment,
                                profitRate: ((tsumitateAsset + growthAsset - totalInvestment) / totalInvestment) * 100
                            })
                            break
                        }
                    }
                } else {
                    tsumitateAsset *= (1 + monthlyRate)
                    growthAsset *= (1 + monthlyRate)
                }
            }

            const totalAsset = tsumitateAsset + growthAsset
            results.push({
                years,
                months: 0,
                assetValue: totalAsset,
                totalInvestment,
                profitRate: (totalAsset - totalInvestment) / totalInvestment * 100
            })

            if (limitReached && years % 5 !== 0) continue
        }

        setCalculationResult(results)

        window.dataLayer.push({
            event: 'nisa_calculation',
            monthlyTsumitate: Number(monthlyTsumitate),
            monthlyGrowth: Number(monthlyGrowth),
            monthlyRate: Number(interestRate),
        })
    }

    const resetCalculation = () => {
        window.location.reload()
    }

    const formatNumber = (num: number) => {
        return num.toLocaleString()
    }

    const renderTable = () => {
        let yearsToShow = [
            ...Array(10).fill(0).map((_, i) => ({ years: i + 1, months: 0 })),
            ...Array(8).fill(0).map((_, i) => ({ years: (i + 3) * 5, months: 0 }))
        ];

        if (yearsToLimit > 0) {
            const limitPoint = { years: yearsToLimit, months: monthsToLimit };
            if (!yearsToShow.some(y => y.years === limitPoint.years && y.months === limitPoint.months)) {
                yearsToShow.push(limitPoint);
            }
            yearsToShow.sort((a, b) => a.years * 12 + a.months - b.years * 12 - b.months);
        }

        return (
            <table style={tableStyles.table}>
                <thead>
                <tr>
                    <th style={tableStyles.th}>経過期間</th>
                    <th style={tableStyles.th}>資産額 (万円)</th>
                    <th style={tableStyles.th}>総積立額 (万円)</th>
                    <th style={tableStyles.th}>利益率 (%)</th>
                </tr>
                </thead>
                <tbody>
                {yearsToShow.map(({ years, months }) => {
                    const result = calculationResult.find(r => r.years === years && r.months === months);
                    if (!result) return null;
                    const isLimitYear = years === yearsToLimit && months === monthsToLimit;
                    const rowStyle = isLimitYear ? { ...tableStyles.td, ...tableStyles.highlightRow } : tableStyles.td;
                    return (
                        <tr key={`${years}-${months}`}>
                            <td style={rowStyle}>
                                {`${years}年${months ? `${months}ヶ月` : ''}`}
                                {isLimitYear ? ' (上限)' : ''}
                            </td>
                            <td style={rowStyle}>{formatNumber(Math.floor(result.assetValue / 10000))}</td>
                            <td style={rowStyle}>{formatNumber(Math.floor(result.totalInvestment / 10000))}</td>
                            <td style={rowStyle}>{result.profitRate.toFixed(2)}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    };

    const renderGraph = () => {
        const chartData = calculationResult.map(result => ({
            year: result.years + result.months / 12,
            資産額: Math.floor(result.assetValue / 10000),
            総積立額: Math.floor(result.totalInvestment / 10000),
        }))

        return (
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis tickFormatter={(value) => formatNumber(value)} />
                    <Tooltip formatter={(value) => formatNumber(value as number)} />
                    <Legend />
                    <Line type="monotone" dataKey="資産額" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="総積立額" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    const title = "新NISA利益計算機"
    const description = "新NISAの積立制約に基づいて、月々の積立額から50年後までの資産の成長とリターンを計算します。総積立額が1800万円に達した後は積立を停止します。"

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="monthlyTsumitate">つみたて投資枠 月々の積立額 (万円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="monthlyTsumitate"
                    value={monthlyTsumitate}
                    onChange={(e) => setMonthlyTsumitate(e.target.value)}
                    min="0"
                    max="10"
                    step="0.1"
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="monthlyGrowth">成長投資枠 月々の積立額 (万円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="monthlyGrowth"
                    value={monthlyGrowth}
                    onChange={(e) => setMonthlyGrowth(e.target.value)}
                    min="0"
                    max="20"
                    step="0.1"
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="interestRate">年利率 (%):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="interestRate"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                    step="0.1"
                />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <div style={{ display: 'flex', gap: '10px' }}>
                <button
                    style={{
                        ...styles.button,
                        opacity: isFormValid ? 1 : 0.5,
                        cursor: isFormValid ? 'pointer' : 'not-allowed'
                    }}
                    onClick={calculateNISA}
                    disabled={!isFormValid}
                >
                    計算
                </button>
                <button
                    style={styles.button}
                    onClick={resetCalculation}
                >
                    リセット
                </button>
            </div>
            {calculationResult.length > 0 && (
                <div style={styles.result}>
                    <p>非課税保有限度額に達するまでにかかる期間: {yearsToLimit}年{monthsToLimit}ヶ月</p>
                    {renderTable()}
                    {renderGraph()}
                </div>
            )}
        </BaseLayout>
    )
}

export default NISACalculator
